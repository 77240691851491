<template>
  <div class="page-custom page-independent-alt container-fluid pt-4 pb-5">
    <div class="container mt-2">
      <div class="row">
        <div class="col-xl-1"></div>
        <div class="col-xl-10 order-xl-1">
          <template v-if="$route.name == 'profile'">
            <card shadow type="secondary">
              <template #header>
                <div class="bg-white border-0">
                  <div class="row align-items-center">
                    <div class="col-8">
                      <h3 class="mb-0">{{ $t.translate("TTL_MY_ACCOUNT") }}</h3>
                    </div>
                  </div>
                </div>
              </template>

              <form v-if="true">
                <div class="pl-lg-4 pr-lg-4">
                  <div class="row">
                    <div class="col-lg-6" v-if="!$h.hasRoles('CLIENT')">
                      <label class="form-control-label">{{
                        $t.translate("LBL_ROLE")
                      }}</label>
                      <div class="block">
                        <base-input
                          :value="$t.translate(`LBL_ROLE_${staffData.UserRole}`)"
                          disabled
                          form-classes="mb-2"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6" v-if="!$h.hasRoles('CLIENT')"></div>

                    <div class="col-lg-6">
                      <label class="form-control-label">{{
                        $t.translate("FRM_FIRSTNAME")
                      }}</label>
                      <base-input
                        v-model="staffData.FirstName"
                        :valid="!$h.isBlank(staffData.FirstName)"
                        form-classes="mb-2"
                      />
                    </div>
                    <div class="col-lg-6">
                      <label class="form-control-label">{{
                        $t.translate("FRM_LASTNAME")
                      }}</label>
                      <base-input
                        v-model="staffData.LastName"
                        :valid="!$h.isBlank(staffData.LastName)"
                        form-classes="mb-2"
                      />
                    </div>

                    <template v-if="!$h.hasRoles('CLIENT')">
                      <div class="col-lg-6">
                        <label class="form-control-label">{{
                          $t.translate("FRM_DISPLAYNAME_CHS")
                        }}</label>
                        <base-input
                          v-model="staffData.DisplayNameCHS"
                          form-classes="mb-2"
                        />
                      </div>

                      <div class="col-lg-6">
                        <label class="form-control-label">{{
                          $t.translate("FRM_DISPLAYNAME_CHT")
                        }}</label>
                        <base-input
                          v-model="staffData.DisplayNameCHT"
                          form-classes="mb-2"
                        />
                      </div>
                    </template>

                    <!-- <div class="col-lg-6">
                      <label class="form-control-label">{{
                        $t.translate("FRM_USERNAME")
                      }}</label>
                      <base-input
                        v-model="staffData.Username"
                        :valid="!$h.isBlank(staffData.Username)"
                        form-classes="mb-2"
                      />
                    </div> -->
                    <div class="col-lg-6">
                      <label class="form-control-label">{{
                        $t.translate("FRM_EMAIL")
                      }}</label>
                      <base-input
                        v-model="staffData.Email"
                        :valid="
                          !$h.isBlank(staffData.Email) &&
                          $h.validateEmail(staffData.Email)
                        "
                        type="email"
                        form-classes="mb-2"
                      />
                    </div>

                    <template v-if="!$h.hasRoles(['CLIENT', 'SUB_CLIENT'])">
                      <div class="col-lg-6">
                        <label class="form-control-label">{{
                          $t.translate("FRM_POSITION")
                        }}</label>
                        <base-input
                          v-model="staffData.Position"
                          :valid="!$h.isBlank(staffData.Position)"
                          form-classes="mb-2"
                        />
                      </div>
                      <div class="col-lg-6">
                        <label class="form-control-label">{{
                          $t.translate("FRM_TEL", "Tel")
                        }}</label>
                        <el-input
                          v-model="staffData.MobileNumber"
                          class="mb-3"
                          :class="{
                            'inp-invalid':
                              $h.isBlank(staffData.MobileNumber) ||
                              !$h.isNumeric(staffData.MobileNumber),
                          }"
                        >
                          <template #prepend>
                            <CountryCode v-model="staffData.MobileCode" />
                          </template>
                        </el-input>
                      </div>
                    </template>
                  </div>
                  <el-button
                    v-if="!$h.hasRoles('CLIENT')"
                    class="mt-3"
                    type="primary"
                    @click="saveChanges"
                  >
                    {{ $t.translate("BTN_SAVE_CHANGES") }}
                  </el-button>
                </div>
              </form>

              <form v-if="$h.hasRoles('CLIENT')">
                <div class="pl-lg-4 pr-lg-4">
                  <div class="row">
                    <div v-if="!profileData.AlreadySaved" class="mt-1 col-lg-12 mb-3">
                      <el-alert
                        :title="
                          $t.translate(
                            'Organization name and registered address cannot be amended once saved. If you want to change it, you has to send request (offline) to HKQAA, and HKQAA would amend the information for you if the request is approved.'
                          )
                        "
                        type="error"
                        :closable="false"
                      />
                    </div>
                  </div>

                  <OrganizationInfo
                    v-if="profileData.Loaded"
                    :user="staffData"
                    :readOnly="profileData.AlreadySaved"
                    :info="profileData.Organization"
                    @update="(v) => updateProfData(v)"
                  />

                  <el-button class="mt-2" type="primary" @click="saveChanges">
                    {{ $t.translate("BTN_SAVE_CHANGES") }}
                  </el-button>
                </div>
              </form>
            </card>

            <card
              v-if="$h.hasRoles('CLIENT') && loaded && profileData?.AssignedBusinessStaff"
              shadow
              type="secondary"
              class="mt-4"
            >
              <template #header>
                <div class="bg-white border-0">
                  <div class="row align-items-center">
                    <div class="col-8">
                      <h3 class="mb-0">
                        {{ $t.translate("FRM_REP_BUSIN_STAFF") }}
                      </h3>
                    </div>
                  </div>
                </div>
              </template>

              <div class="pl-lg-4 pr-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_NAME")
                    }}</label>
                    <div class="block">
                      <base-input
                        :value="
                          $h.formatName(profileData.AssignedBusinessStaff, 'DisplayName')
                        "
                        disabled
                        form-classes="mb-3"
                        placeholder="---"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_POSITION")
                    }}</label>
                    <div class="block">
                      <base-input
                        v-model="profileData.AssignedBusinessStaff.Position"
                        disabled
                        form-classes="mb-3"
                        placeholder="---"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_TEL")
                    }}</label>
                    <div class="block">
                      <el-input
                        v-model="profileData.AssignedBusinessStaff.MobileNumber"
                        disabled
                        class="mb-3"
                      >
                        <template #prepend>
                          <CountryCode
                            disabled
                            v-model="profileData.AssignedBusinessStaff.MobileCode"
                          />
                        </template>
                      </el-input>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_EMAIL")
                    }}</label>
                    <div class="block">
                      <base-input
                        v-model="profileData.AssignedBusinessStaff.Email"
                        disabled
                        form-classes="mb-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </template>

          <card v-else shadow type="secondary" class="mt-4">
            <template #header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t.translate("TTL_CHANGE_PASSWORD") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <div class="pl-lg-4 pr-lg-4">
                <div class="row">
                  <div class="col-lg-12" style="margin-bottom: 20px">
                    <el-alert
                      :closable="false"
                      :title="$t.translate('MSG_ERR_PASSWORD_INVALID')"
                      type="warning"
                    />
                  </div>

                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_CURRENT_PASSWORD")
                    }}</label>
                    <base-input
                      v-model="passwordData.CurrentPassword"
                      type="password"
                      :reveal="true"
                      input-classes="form-control-alternative"
                      addon-left-icon="ni ni-lock-circle-open"
                    />
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_NEW_PASSWORD")
                    }}</label>
                    <base-input
                      v-model="passwordData.NewPassword"
                      type="password"
                      :reveal="true"
                      input-classes="form-control-alternative"
                      addon-left-icon="ni ni-lock-circle-open"
                    />
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_CONFIRM_PASSWORD")
                    }}</label>
                    <base-input
                      v-model="passwordData.ConfirmPassword"
                      type="password"
                      :reveal="true"
                      input-classes="form-control-alternative"
                      addon-left-icon="ni ni-lock-circle-open"
                    />
                  </div>
                </div>
                <el-button type="primary" class="" @click="changePassword">
                  {{ $t.translate("BTN_SUBMIT") }}
                </el-button>
              </div>
            </form>
          </card>

          <card
            v-if="$route.name == 'profile' && $h.hasRoles('BUSINESS') && loaded"
            shadow
            class="mt-4"
          >
            <template #header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t.translate("TTL_HANDLED_CLIENTS") }}</h3>
                  </div>

                  <div class="col-4 d-flex justify-content-end">
                    <el-button
                      class=""
                      type="primary"
                      size="small"
                      @click="inviteClientModal"
                    >
                      {{ $t.translate("BTN_INVITE") }}
                    </el-button>
                  </div>
                </div>
              </div>
            </template>

            <div class="row">
              <div class="col-lg-12">
                <div class="mb-3">
                  <Table
                    url="/user/list"
                    :query="{ StaffKey: staffData.UserKey, RoleCode: 'CLIENT' }"
                    ref="filttable"
                  >
                    <el-table-column
                      :label="$t.translate('FRM_ORGANIZATION_NAME')"
                      min-width="182"
                      class-name="pl-1"
                    >
                      <template #default="scope">
                        {{ $h.formatName(scope.row, "OrgName") }}
                      </template>
                    </el-table-column>

                    <el-table-column
                      :label="$t.translate('LBL_REP_NAME')"
                      min-width="190"
                    >
                      <template #default="scope">
                        {{ scope.row.RepName || "---" }}
                      </template>
                    </el-table-column>

                    <el-table-column
                      :label="$t.translate('LBL_REP_EMAIL')"
                      min-width="190"
                    >
                      <template #default="scope">
                        {{ scope.row.RepEmail || "---" }}
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t.translate('FRM_EMAIL')" min-width="190">
                      <template #default="scope">
                        {{ scope.row.Email || "---" }}
                      </template>
                    </el-table-column>

                    <el-table-column :label="$t.translate('LBL_STATUS')" min-width="100">
                      <template #default="scope">
                        <el-tag
                          :type="
                            scope.row.UserStatusCode === 'ACTIVE'
                              ? 'primary'
                              : scope.row.UserStatusCode == 'INVITED'
                              ? 'warning'
                              : 'info'
                          "
                          disable-transitions
                          >{{
                            $t.translate(`LBL_USER_${scope.row.UserStatusCode}`)
                          }}</el-tag
                        >
                      </template>
                    </el-table-column>

                    <el-table-column
                      :label="$t.translate('FRM_LAST_CREATED')"
                      width="210"
                    >
                      <template #default="scope">
                        {{ $h.formatDateTime(scope.row.LastCreated) }}
                      </template>
                    </el-table-column>
                  </Table>
                </div>
              </div>
            </div>

            <modal v-model:show="showModal">
              <template #header>
                <h2 id="invModalLabel" class="modal-title text-dark">
                  {{ $t.translate("TTL_FI_CLIENT_INFO") }}
                </h2>
              </template>
              <div class="disc-content mt--4">
                <div class="row">
                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_CLIENT_EMAIL")
                    }}</label>

                    <el-input
                      class="mb-3"
                      v-model="newClientData.Email"
                      :class="{
                        'inp-invalid':
                          $h.isBlank(newClientData.Email) ||
                          !$h.validateEmail(newClientData.Email),
                      }"
                    />
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("LBL_ORGANIZATION_NAME")
                    }}</label>

                    <el-input
                      class="mb-3"
                      show-word-limit
                      :maxlength="$h.getCharLimit().OrgName"
                      v-model="newClientData.Company"
                      :class="{
                        'inp-invalid': $h.isBlank(newClientData.Company),
                      }"
                    />
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_BRANCH_OPTIONAL")
                    }}</label>
                    <el-input
                      v-model="newClientData.Branch"
                      class="mb-3"
                      show-word-limit
                      :maxlength="
                        $h.getCharLimit().BranchName - (newClientData.Company.length + 1)
                      "
                    />
                  </div>
                </div>
              </div>

              <template #footer>
                <el-button type="primary" @click="inviteClient">{{
                  $t.translate("BTN_SEND_INV")
                }}</el-button>
              </template>
            </modal>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { post, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { store } from "@/store";
import Table from "@/components/Table.vue";
import OrganizationInfo from "@/components/OrganizationInfo";
import CountryCode from "@/components/CountryCode";
import StaffsVue from "./Staffs.vue";
import { router } from "@/utils/router";
import { translation } from "@/utils/translation";
import { ElMessageBox } from "element-plus";
import { useRoute } from "vue-router";

export default {
  components: {
    Table,
    OrganizationInfo,
    CountryCode,
  },
  setup() {
    const route = useRoute();

    let staffData = reactive({
      Company: "",
      Branch: "",
      UserKey: "",
      FirstName: "",
      LastName: "",
      DisplayNameCHS: "",
      DisplayNameCHT: "",
      Username: "",
      Email: "",
      UserRole: "",

      MobileCode: "",
      MobileNumber: "",
      Position: "",
    });

    let profileData = reactive({
      AlreadySaved: false,
      Organization: {},
      AssignedBusinessStaff: null,
    });
    let passwordData = reactive({
      CurrentPassword: "",
      NewPassword: "",
      ConfirmPassword: "",
    });
    let ClientList = ref([]); // the clients that the business staff handles
    let showModal = ref(false);
    let newClientData = reactive({
      Email: "",
      Company: "",
      Branch: "",
    });
    let loaded = ref(false);

    onMounted(async () => {
      if (route?.query?.redirect) {
        //set now
        await store.dispatch("user/setData", {
          Redirect: route?.query?.redirect,
        });
      }

      await store.dispatch("user/fetchData");
      let User = store.getters["user/getData"]?.UserData;
      if (User) {
        profileData.AlreadySaved = User.IsHaveOrganization ? true : false;
        profileData.Loaded = true;
        profileData.Organization = User.Organization || {};
        profileData.Organization.GeneralEmail = User?.Organization?.Email || "";

        for (let key of Object.keys(staffData)) {
          staffData[key] = User[key];
        }
        profileData.AssignedBusinessStaff = User.AssignedBusinessStaff || null;
      }

      loaded.value = true;
    });

    const changePassword = async () => {
      let res = await post("/user/password", passwordData);

      if (isValidResponse(res)) {
        passwordData.CurrentPassword = "";
        passwordData.NewPassword = "";
        passwordData.ConfirmPassword = "";
        helpers.showMessage({
          Code: "MSG_PASSWORD_UPDATED",
          Message: "Password successfully updated!",
        });
      }
    };

    const inviteClientModal = () => {
      let keys = Object.keys(newClientData);
      for (let key of keys) {
        newClientData[key] = ""; //reset
      }
      showModal.value = true;
    };

    const inviteClient = async () => {
      let res = await post("/user/invite", newClientData);
      if (isValidResponse(res)) {
        showModal.value = false;
        helpers.showMessage({
          Code: "MSG_EMAIL_INVIT_SENT",
          Message: "MSG_EMAIL_INVIT_SENT",
        });
      }
    };

    const saveChanges = async () => {
      let hasOrganization = profileData.AlreadySaved;
      let User = store.getters["user/getData"]?.UserData;
      let IsChanging = false;

      try {
        if (staffData.Email != User.Email) {
          IsChanging = true;

          await ElMessageBox.confirm(
            translation.translate("MSG_WARNING_CHANGE_EMAIL_ADDRESS"),
            translation.translate("TTL_WARNING"),
            {
              confirmButtonText: translation.translate("BTN_OKAY"),
              cancelButtonText: translation.translate("BTN_CANCEL"),
              type: "warning",
            }
          );
        }

        let submitData = {
          ...staffData,
        }; //default

        if (User.UserRole == "CLIENT") {
          submitData = {
            ...staffData,
            ...profileData.Organization,
          };
        }

        let res = await post("/user/update/profile", submitData);
        if (isValidResponse(res)) {
          if (IsChanging) {
            await store.dispatch("user/resetData");
            await store.dispatch("page/setData", { isSuccessReg: true });
            router.replaceRoute("/login");
          } else {
            profileData.AlreadySaved = true;
            await store.dispatch("user/fetchData");

            //show succ message
            helpers.showMessage({
              Code: "MSG_PROFILE_UPDATED",
              Message: "Profile successfully updated!",
            });

            //redirect to dashboard
            if (!hasOrganization) {
              await helpers.redirect({
                path: "/dashboard",
                urlParams: {},
                router,
              });
            }
          }
        }
      } catch (err) {
        //err
        if (IsChanging) {
          staffData.Email = User.Email;
        }
      }
    };

    const updateProfData = (data) => {
      profileData.Organization = { ...data };
    };

    return {
      loaded,
      updateProfData,
      saveChanges,
      changePassword,
      inviteClient,
      inviteClientModal,
      staffData,
      profileData,
      passwordData,
      ClientList,
      showModal,
      newClientData,
    };
  },
};
</script>
<style></style>
